import React from "react";
import "../styling/About.css";
import Confort1 from "../assets/confort3.jpg";
import Confort2 from "../assets/confort2.jpg";

function About() {
  return (
    <div className="about" id="about">
      <div className="container-about">
        <div className="content-about">
          <div className="eyebrow-about">
            <div className="line"></div>
            <div className="text-about">ABOUT</div>
          </div>
          <div className="slogan1-about">
            We help to bring your dream space to reality.
          </div>
          <div className="slogan3-about">
            Many years of experience in the assembly of luxury furnishings,
            application of boiserie, application of various gold decorations.
            The basis of this profession is a lot of patience, love for beauty
            and a passion that goes beyond simple screws and pieces of wood.
          </div>
        </div>
        <div className="section-about">
          <div className="about-creative">
            <img className="image" src={Confort1} alt="presentation" />
            <img className="image2" src={Confort2} alt="presentation" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
