import React from "react";
import "../styling/Footer.css";
import Logo from "../assets/logo-circle.png";

function Footer() {
  return (
    <div className="footer-67">
      <div className="upper-footer">
        <div className="site-logo">
          <img src={Logo} alt="presentation" className="site-logo-img" />
        </div>
        <div className="right-upper-footer">
          <div className="title-footer">Confort Uno Mobili srl</div>
          <div className="slogan-footer">
            Professionalism and attention to detail. We treat furniture like
            museum pieces or works of art.
          </div>
        </div>
      </div>
      <div className="down-footer">
        <div className="socials">
          <svg
            className="social"
            width="12"
            height="22"
            viewBox="0 0 12 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 0H8.72727C7.28064 0 5.89325 0.579463 4.87033 1.61091C3.8474 2.64236 3.27273 4.04131 3.27273 5.5V8.8H0V13.2H3.27273V22H7.63636V13.2H10.9091L12 8.8H7.63636V5.5C7.63636 5.20826 7.7513 4.92847 7.95588 4.72218C8.16047 4.51589 8.43795 4.4 8.72727 4.4H12V0Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <svg
            className="social2"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.76153 1.02344C3.14476 1.02344 1.02344 3.14476 1.02344 5.76153V16.2377C1.02344 18.8545 3.14476 20.9758 5.76153 20.9758H16.2377C18.8545 20.9758 20.9758 18.8545 20.9758 16.2377V5.76153C20.9758 3.14476 18.8545 1.02344 16.2377 1.02344H5.76153ZM0.0234375 5.76153C0.0234375 2.59247 2.59247 0.0234375 5.76153 0.0234375H16.2377C19.4068 0.0234375 21.9758 2.59247 21.9758 5.76153V16.2377C21.9758 19.4068 19.4068 21.9758 16.2377 21.9758H5.76153C2.59247 21.9758 0.0234375 19.4068 0.0234375 16.2377V5.76153ZM11.5863 7.30355C10.8184 7.18969 10.0342 7.32084 9.34523 7.67835C8.65622 8.03587 8.09748 8.60154 7.74849 9.2949C7.3995 9.98827 7.27802 10.774 7.40135 11.5404C7.52467 12.3068 7.8865 13.0148 8.43539 13.5637C8.98428 14.1126 9.69226 14.4744 10.4586 14.5977C11.225 14.721 12.0108 14.5996 12.7042 14.2506C13.3975 13.9016 13.9632 13.3428 14.3207 12.6538C14.6782 11.9648 14.8094 11.1806 14.6955 10.4128C14.5794 9.62954 14.2144 8.90443 13.6545 8.34455C13.0946 7.78466 12.3695 7.41969 11.5863 7.30355ZM8.88466 6.79073C9.76037 6.33634 10.7571 6.16965 11.733 6.31436C12.7284 6.46198 13.65 6.92584 14.3616 7.63744C15.0732 8.34903 15.5371 9.27063 15.6847 10.2661C15.8294 11.242 15.6627 12.2387 15.2083 13.1144C14.7539 13.9901 14.035 14.7002 13.1537 15.1438C12.2725 15.5874 11.2738 15.7417 10.2998 15.585C9.32573 15.4283 8.4259 14.9684 7.72828 14.2708C7.03067 13.5732 6.57078 12.6733 6.41405 11.6993C6.25731 10.7252 6.4117 9.72656 6.85525 8.84531C7.29881 7.96407 8.00895 7.24512 8.88466 6.79073ZM16.7615 4.73772C16.4854 4.73772 16.2615 4.96158 16.2615 5.23772C16.2615 5.51387 16.4854 5.73772 16.7615 5.73772H16.7715C17.0477 5.73772 17.2715 5.51387 17.2715 5.23772C17.2715 4.96158 17.0477 4.73772 16.7715 4.73772H16.7615Z"
              fill="black"
            />
          </svg>

          <svg
            className="social3"
            width="31"
            height="24"
            viewBox="0 0 31 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M28.4129 5.13066C28.2617 4.5167 27.9538 3.95417 27.5203 3.49988C27.0868 3.04559 26.543 2.71563 25.9439 2.54334C23.7549 2 14.999 2 14.999 2C14.999 2 6.24312 2 4.05414 2.59508C3.45503 2.76738 2.91126 3.09734 2.47775 3.55163C2.04424 4.00591 1.73635 4.56845 1.58518 5.18241C1.18456 7.44056 0.988597 9.73132 0.999751 12.0259C0.985471 14.3377 1.18145 16.6459 1.58518 18.9211C1.75184 19.516 2.06663 20.0571 2.49913 20.4922C2.93163 20.9273 3.46721 21.2417 4.05414 21.4049C6.24312 22 14.999 22 14.999 22C14.999 22 23.7549 22 25.9439 21.4049C26.543 21.2326 27.0868 20.9027 27.5203 20.4484C27.9538 19.9941 28.2617 19.4316 28.4129 18.8176C28.8104 16.5764 29.0063 14.3031 28.9983 12.0259C29.0126 9.71404 28.8166 7.40582 28.4129 5.13066V5.13066Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 16L20 12L12 8V16Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <svg
            className="social4"
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.04785 2.7498C1.04785 1.80887 1.80675 1.0498 2.73833 1.0498C3.66991 1.0498 4.4288 1.80887 4.4288 2.7498C4.4288 3.69074 3.66991 4.4498 2.73833 4.4498C1.80675 4.4498 1.04785 3.69074 1.04785 2.7498ZM2.73833 0.0498047C1.25038 0.0498047 0.0478516 1.26068 0.0478516 2.7498C0.0478516 4.23893 1.25038 5.4498 2.73833 5.4498C4.22628 5.4498 5.4288 4.23893 5.4288 2.7498C5.4288 1.26068 4.22628 0.0498047 2.73833 0.0498047ZM15.8812 7.6498C14.2716 7.6498 12.7276 8.29195 11.5888 9.43569C10.4499 10.5795 9.80976 12.1313 9.80976 13.7498V20.9498H13.1907V13.7498C13.1907 13.0344 13.4736 12.3479 13.978 11.8414C14.4824 11.3348 15.1669 11.0498 15.8812 11.0498C16.5954 11.0498 17.28 11.3348 17.7844 11.8414C18.2887 12.3479 18.5717 13.0344 18.5717 13.7498V20.9498H21.9526V13.7498C21.9526 12.1313 21.3124 10.5795 20.1736 9.43569C19.0348 8.29195 17.4907 7.6498 15.8812 7.6498ZM15.8812 6.6498C14.005 6.6498 12.2061 7.39837 10.8802 8.73011C9.55426 10.0618 8.80976 11.8675 8.80976 13.7498V21.4498C8.80976 21.7259 9.03361 21.9498 9.30976 21.9498H13.6907C13.9669 21.9498 14.1907 21.7259 14.1907 21.4498V13.7498C14.1907 13.2982 14.3693 12.8656 14.6866 12.547C15.0038 12.2284 15.4335 12.0498 15.8812 12.0498C16.3288 12.0498 16.7586 12.2284 17.0758 12.547C17.393 12.8656 17.5717 13.2982 17.5717 13.7498V21.4498C17.5717 21.7259 17.7955 21.9498 18.0717 21.9498H22.4526C22.7288 21.9498 22.9526 21.7259 22.9526 21.4498V13.7498C22.9526 11.8675 22.2081 10.0618 20.8822 8.73011C19.5562 7.39837 17.7573 6.6498 15.8812 6.6498ZM0.0478516 8.2498C0.0478516 7.97366 0.271709 7.7498 0.547852 7.7498H4.9288C5.20495 7.7498 5.4288 7.97366 5.4288 8.2498V21.4498C5.4288 21.7259 5.20495 21.9498 4.9288 21.9498H0.547852C0.271709 21.9498 0.0478516 21.7259 0.0478516 21.4498V8.2498ZM1.04785 8.7498V20.9498H4.4288V8.7498H1.04785Z"
              fill="black"
            />
          </svg>
        </div>

        <div className="copyright">
            builded by{" "}
            <a className="agency" href="https://www.upvisionmedia.com"> upvisionMedia</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
