import React from "react";
import "../styling/Home.css";
import UseScroll from "../js/UseScroll";

function Home() {
  return (
    <div className="home-container" id="home">
      <div className="creative">
        <button className="cta-button">
          <a
            href="#0"
            className="button-cta"
            onClick={() => {
              UseScroll("contact");
            }}>
            <i className="label-home">✉</i>
          </a>
        </button>
      </div>
      <div className="content-home">
        <h3>Crafting Elegance, One Piece at a Time</h3>
        <p>
          Your Premium Partner in Luxury Furniture Installation for Homes,
          Hotels and Shops.
        </p>
      </div>
    </div>
  );
}

export default Home;
