import React, { useState } from "react";
import "../styling/Contact.css";
import Confort1 from "../assets/confort2.jpg";
import * as emailjs from "@emailjs/browser";
import { contactConfig } from "../data/contactConfig";

function Contact() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    message: "",
    loading: false,
    show: false,
    alertMessage: "",
    variant: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({ loading: true });

    const templateParams = {
      from_name: formData.email,
      user_name: formData.name,
      to_name: contactConfig.YOUR_EMAIL,
      message: formData.message,
    };

    emailjs
      .send(
        contactConfig.YOUR_SERVICE_ID,
        contactConfig.YOUR_TEMPLATE_ID,
        templateParams,
        contactConfig.YOUR_USER_ID
      )
      .then(
        (res) => {
          console.log("🚀 ~ file: Contact.jsx:36 ~ handleSubmit ~ res:", res);
          setFormData({
            loading: false,
            alertMessage:
              "Thank you for the message, we'll answer as soon as possible! Have a nice day!",
            variant: "success",
            show: true,
          });
        },
        (error) => {
          console.log(error.text);
          setFormData({
            alertMessage: `Failed to send!, ${error.message}`,
            variant: "error",
            show: true,
          });
        }
      );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="contact" id="contact">
      <div className="leftside-contact">
        <div className="eyebrow-contact">
          <div className="line"></div>
          <div className="text">CONTACT</div>
        </div>
        <div className="text-holder">
          <div className="text-contact">
            We help to bring your dream space to reality.
          </div>
          <div className="text1-contact">
            Many years of experience in the assembly of luxury furnishings,
            application of boiserie, application of various gold decorations.
          </div>
        </div>
        <section className="get-in-touch">
          <form className="contact-form row">
            <div className="form-field col x-50">
              <input
                id="name"
                className="input-text js-input"
                placeholder="You name"
                value={formData.name || ""}
                type="text"
                required
                onChange={handleChange}
              />
            </div>
            <div className="form-field col x-50">
              <input
                id="email"
                className="input-text js-input"
                name="email"
                placeholder="Please, your email address"
                type="email"
                value={formData.email || ""}
                required
                onChange={handleChange}
              />
            </div>
            <div className="form-field col x-100">
              <input
                id="message"
                className="input-text js-input"
                placeholder="Let's get in touch "
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-field col x-100 align-center">
              <button
                className="submit-btn"
                type="submit"
                value="Submit"
                onClick={handleSubmit}>
                {formData.loading ? "Sending..." : "Send"}
              </button>
            </div>
          </form>
        </section>
      </div>
      <div className="rightside-contact">
        <img className="image-contact" src={Confort1} alt="" />
      </div>
    </div>
  );
}

export default Contact;
