import React from "react";
import "../styling/Services.css";

function Services() {
  return (
    <div className="services" id="services">
      <div className="content-services">
        <div className="eyebrow-services">
          <div className="line"></div>
          <div className="text-services">OUR SERVICES</div>
        </div>
      </div>
      <div className="services2">
        <div className="card-service">
          <div className="top image1-services">
            <p className="title-service">Hotel Furniture</p>
          </div>
          <p className="desc">
            We have collaborated with the largest hotels in the world to make
            their rooms the most elegant and refined. The assembly of hotel
            furniture is not a game and above all it requires a lot of attention
            to detail to allow the guest to have the most premium experience
            possible.
          </p>
        </div>
        <div className="card-service">
          <div className="top image2-services">
            <p className="title-service">Private Home</p>
          </div>
          <p className="desc">
            We also take care of the homes of individuals who want to freshen up
            their environment by choosing luxury furniture with the most
            particular and difficult to achieve details. We transform dreams and
            drawings on paper into real housing works of art.e and quality with
            our premium furniture assembly service.
          </p>
        </div>
        <div className="card-service">
          <div className="top image3-services">
            <p className="title-service">Shops</p>
          </div>
          <p className="desc">
            We also deal with the assembly of shop furniture. In special luxury
            shops with important details and pieces of furniture that are
            complex to assemble.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
