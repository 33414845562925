const contactConfig = {
  YOUR_EMAIL: "a.stanciu@upvisionmedia.com",
  YOUR_PHONE: "+40 744 497740",
  description: "Contact me if i can help you or your business!",
  YOUR_SERVICE_ID: "service_qyrvp76",
  YOUR_TEMPLATE_ID: "template_khww5ls",
  YOUR_USER_ID: "tM4xpBMGBthi0_IiN",
};

export { contactConfig };
