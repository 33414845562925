import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Works from "./pages/Works";
import "./styling/App.css";
import CookieConsent from "react-cookie-consent";
import { Element } from "react-scroll";

function App() {
  return (
    <div className="App">
      <CookieConsent
        location="bottom"
        buttonText="Sure man!!"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", zIndex: 10000 }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}>
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <Navbar />

      <Element name="home">
        <Home />
      </Element>
      <Element name="about">
        <About />
      </Element>
      <Element name="services">
        <Services />
      </Element>
      <Element name="works">
        <Works />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
      <Footer />
    </div>
  );
}

export default App;
