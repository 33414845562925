import React, { useState } from "react";
import "../styling/Navbar.css";
import Logo from "../assets/logo-confort.png";
import UseScroll from "../js/UseScroll";

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const closeNavbar = () => {
    setIsNavOpen(false);
  };

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={`nav ${isNavOpen ? "nav-open" : ""}`}>
      <input type="checkbox" id="nav-check" />
      <div className="nav-header">
        <div className="nav-title">
          <img
            className="image-logo"
            src={Logo}
            alt="confort uno mobili"
            onClick={() => {
              UseScroll("home");
              closeNavbar();
            }}
          />
        </div>
      </div>
      <div className="nav-btn">
        <label htmlFor="nav-check" onClick={toggleNavbar}>
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>

      <div className="nav-links">
        <button
          href="/"
          className="button-navbar"
          onClick={() => {
            UseScroll("about");
            closeNavbar();
          }}>
          about
        </button>
        <button
          href="/"
          className="button-navbar"
          onClick={() => {
            UseScroll("services");
            closeNavbar();
          }}>
          services
        </button>
        <button
          href="/"
          className="button-navbar"
          onClick={() => {
            UseScroll("works");
            closeNavbar();
          }}>
          works
        </button>
        <button
          href="/"
          className="button-navbar"
          onClick={() => {
            UseScroll("contact");
            closeNavbar();
          }}>
          contact
        </button>
      </div>
    </div>
  );
}

export default Navbar;
