import React from "react";
import "../styling/Works.css";
import Image1 from "../assets/confort5.jpg";
import Image2 from "../assets/confort4.jpg";
import Image3 from "../assets/confort8.jpg";
import Image4 from "../assets/confort10.jpg";

function Works() {
  return (
    <div className="works" id="works">
      <div className="col-1">
        <div className="content-works">
          <div className="eyebrow-works">
            <div className="line"></div>
            <div className="text-works">RECENT WORKS</div>
          </div>
          <div className="title1">Some of our crafts made with love.</div>
        </div>
        <div className="project">
          <img className="image-works" src={Image3} alt="" />
          <div className="content2">
            <div className="title-works">Shops Furnishing &amp; Interior</div>
            Discover opulent elegance with our luxury furniture collection.
            Immerse yourself in timeless designs, handcrafted from the finest
            materials, and meticulously curated for the most discerning tastes.
            Elevate your living spaces with unparalleled craftsmanship and
            sophistication. Explore our exclusive range and indulge in the
            epitome of luxury living.
          </div>
        </div>
        <div className="project">
          <img className="image-works" src={Image4} alt="" />
          <div className="content2">
            <div className="title-works">Private Luxury Details</div>
            Elevate your living space with our exclusive Private Luxury Details
            Furniture Assembly service. Immerse yourself in opulence as our
            skilled craftsmen meticulously assemble each piece, transforming
            your home into a sanctuary of sophistication and style. We
            understand that luxury resides in the finer details, and our expert
            artisans ensure every element is flawlessly integrated. From
            sumptuous leather recliners to bespoke hardwood cabinets, our team
            showcases unparalleled precision, ensuring your furniture exudes
            timeless elegance. We prioritize discretion, arriving in unmarked
            vehicles to protect your privacy. Experience the epitome of
            refinement with our Private Luxury Details Furniture Assembly, where
            every item is curated to perfection, and your comfort is our utmost
            priority.
          </div>
        </div>
      </div>
      <div className="col-2">
        <div className="project">
          <img className="image-works" src={Image1} alt="" />
          <div className="content2">
            <div className="title-works">Luxury Hotel Renovation</div>
            Elevate your luxury hotel renovation with our exquisite furniture
            description service. We curate opulent furnishings that harmonize
            with your vision, adding elegance and sophistication to your space.
            Our attention to detail ensures a seamless integration of style and
            comfort, creating an unforgettable guest experience.
          </div>
        </div>
        <div className="project">
          <img className="image-works" src={Image2} alt="" />
          <div className="content2">
            <div className="title-works">Residence Furniture</div>
            Our residence renovation furniture description service provides
            concise, compelling, and informative descriptions for your furniture
            pieces. We highlight their style, functionality, and quality,
            helping you showcase the perfect blend of aesthetics and utility in
            your newly renovated home. Make your furniture stand out with our
            succinct and engaging descriptions.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Works;
